import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { updateMerchantData } from '../../Actions/StoreActions';
import { updateCurrentUser, loginUser } from '../../Actions/UserActions';

import { getMerchant } from '../../Helpers/store_helpers';
import { getDomainFromUrl } from '../../Helpers/formatting';
import {
  getAdjPayoutRate,
  getCollections,
  getNotificationCount,
  getName,
  getUsername,
  getEmail,
  hasUsedSnapShop,
  getUserId,
  isSimulatingUser,
  getUserTier,
  isBanned,
  isBrand,
  isLoggedIn
} from '../../Helpers/user_helpers';
import { createPin, deletePin, getProductFromUrl } from '../../APIClient/pins';
import { logToSlack } from '../../APIClient/alerts';
import { getUrlParam } from '../../Helpers/helpers';

const Snapshop = props => {
  const { store, user, updateCurrentUser, updateMerchantData, loginUser } = props;
  const loggedIn = isLoggedIn(user);
  const userByPassToken = getUrlParam('hash');

  // Declan, put this on extension:
  /*
    const handleDataFromWebIframe = async request => {
      const { command } = request;
      switch (command) {
        case 'TEMPLATE':
          console.error('GOT DATA!!')
        default:
          break;
      }
    }
     eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
     eventer = window[eventMethod];
     messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
     eventer(messageEvent, function({ data }) {
        if (!window.fromShopMyWeb) return null
        handleDataFromWebIframe(data)
     });
  */

  const handleRequest = async request => {
    /*
      From browser:
        const iframe = document.getElementById('test').contentWindow
        iframe.postMessage({command: 'COMMAND', fromQuickLinksExtension: true, ...}, '*')
    */
    const submitData = data => window.parent.postMessage({ ...data, command, fromShopMyWeb: true }, '*');

    const { command, href } = request;
    const domain = getDomainFromUrl(href);

    switch (command) {
      case 'GET_MERCHANT_DATA':
        // @param {string} href - the location of the page searching from

        const resp = await updateMerchantData([domain]);
        const productInfo = await getProductFromUrl(href);

        const merchant = _.get(resp, ['data', domain]) || getMerchant(store, domain);
        const userRate = getAdjPayoutRate(user, merchant, productInfo.variant);

        if (productInfo.merchants)
          for (const other_merchant of productInfo.merchants) other_merchant.adjustedRate = getAdjPayoutRate(user, other_merchant).toFixed();

        return submitData({ merchant, userRate, productInfo });

      case 'GET_COLLECTIONS_DATA':
        // no params needed
        const collections = await getCollections(user);
        return submitData({ collections });

      case 'BOOKMARKLET_VERSION':
        if (user.currentSnapshopVersion === request.bookmarklet_snapshop_version) return;
        else await updateCurrentUser({ currentSnapshopVersion: request.bookmarklet_snapshop_version }, getUserId(user));
        break;

      case 'CREATE_PIN':
        // @param {number} collection_id - id of the collection to add the pin to
        // @param {string} title - title user wants for new pin
        // @param {string} description - description user wants for new pin
        // @param {number} image_src - src of the image the user wants to use
        const { collection_id, title, description, image_src } = request;

        const pin_data = {
          title,
          description: description ? description : undefined,
          link: href,
          image: image_src,
          User_id: user.profile.id,
          Collection_id: collection_id,
          fromSnapShop: true,
          disable_monetization: isBrand(user) || isBanned(user) ? true : false
        };
        const created_pin = await createPin(pin_data);
        return submitData({ pin: created_pin });

      case 'DELETE_PIN':
        // @param {number} pin_id - id of the pins that will be deleted
        const { pin_id } = request;

        const deleted_pin = await deletePin(pin_id);
        return submitData({ deleted_pin });

      case 'GET_NOTIFICATION_COUNT':
        const notification_count = getNotificationCount(user);

        return submitData({ notification_count });

      case 'SEND_HELP_MESSAGE':
        // @param {string} message - the string that the user entered into the help box
        const { message: user_message } = request;

        const username = getUsername(user);
        const email = getEmail(user);
        const name = getName(user);

        const message = `user: ${name} (${
          getUserTier(user)?.score
        }) - ${email}\nshop_url: https://shopmy.us/${username}\n\nurl: ${href}\nmessage: ${user_message}`;
        await logToSlack({ message, channel: 'snapshop-issues' });

        return submitData({ success: true });

      case 'TEMPLATE':
        // @param {string} href - the location of the page searching from

        /*
          iframe.postMessage({
            command: 'TEMPLATE', 
            fromQuickLinksExtension: true,
            ...
          }, '*')
        */
        return submitData({});
      default:
        break;
    }
  };

  const updateUserIfFirstTimeUsingSnapshop = async () => {
    if (hasUsedSnapShop(user)) return;
    !isSimulatingUser(user) && (await updateCurrentUser({ hasSnapShop: true }, getUserId(user)));
  };

  // Set up Listener for Messages from Web Page
  useEffect(() => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    const handleEvent = ({ data }) => {
      const isnt_relevant = !data?.fromQuickLinksExtension;
      const user_isnt_logged_in = !loggedIn;
      if (isnt_relevant || user_isnt_logged_in) return;

      handleRequest(data);
    };

    eventer(messageEvent, handleEvent);
    if (!user.jwtToken && userByPassToken) loginUser({ userHash: userByPassToken });

    return () => window.removeEventListener(messageEvent, handleEvent);
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn) return;

    updateUserIfFirstTimeUsingSnapshop();
    window.parent.postMessage({ command: 'ALLOW_REQUESTS', fromShopMyWeb: true, user }, '*');
  }, [loggedIn]);

  return (
    <div className='template-outer-container'>
      <div></div>
    </div>
  );
};

Snapshop.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  updateMerchantData: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, store } = state;
  return { user, store };
};

export default connect(mapStateToProps, {
  updateMerchantData,
  updateCurrentUser,
  loginUser
})(Snapshop);
