import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import './ConnectSocialsPanel.scss';

import { openConnectInstagramModal } from '../../../../Actions/UIActions';
import { disconnectSocialAccount, connectInstagram, connectYoutube, connectTiktok } from '../../../../Actions/UserActions';

import { isBrand, getSocialAccount, getBrandSocialAccount } from '../../../../Helpers/user_helpers';
import { openInstagramAuthModal, openYoutubeAuthModal, openTikTokAuthModal } from '../../../../Helpers/social_helpers';

import FacebookSDK from '../../../../Components/General/FacebookSDK';
import { ReactComponent as InstagramIcon } from '../../../../static/images/social_icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../../../../static/images/social_icons/tiktok.svg';
import { ReactComponent as YoutubeIcon } from '../../../../static/images/social_icons/youtube.svg';

const ConnectSocialsPanel = props => {
  const { user } = props;

  const [isAddingSocialAccountType, setIsAddingSocialAccountType] = React.useState(null);

  const getAccountFn = isBrand(user) ? getBrandSocialAccount : getSocialAccount;
  const instaAccount = getAccountFn(user, 'instagram');
  const youtubeAccount = getAccountFn(user, 'youtube');
  const tiktokAccount = getAccountFn(user, 'tiktok');

  const disconnectAccount = type => {
    const account = getAccountFn(user, type);
    confirmAlert({
      title: 'Just confirming',
      message: isBrand(user)
        ? `Are you sure you want to disconnect this account? This will effect measuring accuracy on your social performance analytics.`
        : `Are you sure you want to disconnect this account? This will remove all reporting on your brand collaborations.`,
      buttons: [
        { label: 'No', onClick: () => {}, className: 'cancel' },
        {
          label: 'Yes',
          onClick: () => props.disconnectSocialAccount(account)
        }
      ]
    });
  };

  const accountConnections = [
    {
      type: 'instagram',
      Icon: InstagramIcon,
      isVisible: true, // Brands and Creators
      account: instaAccount,
      connectAccount: () => {
        openInstagramAuthModal(
          async authResponse => {
            setIsAddingSocialAccountType('instagram');
            await props.connectInstagram(authResponse);
            setIsAddingSocialAccountType(null);
          },
          () => props.openConnectInstagramModal()
        );
      }
    },
    {
      type: 'youtube',
      Icon: YoutubeIcon,
      isVisible: !isBrand(user), // Creators Only
      account: youtubeAccount,
      connectAccount: openYoutubeAuthModal
    },
    {
      type: 'tiktok',
      Icon: TiktokIcon,
      isVisible: !isBrand(user), // Creators Only
      account: tiktokAccount,
      connectAccount: openTikTokAuthModal
    }
  ].filter(({ isVisible }) => isVisible);

  return (
    <div className='connect-socials-panel-outer'>
      <FacebookSDK />

      <div className='connect-socials-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Connect Your Social Accounts</div>
          {isBrand(user) ? (
            <div className='settings-section-subtitle'>
              Connect your social accounts for a higher level of accuracy on your brand's social mentions.
            </div>
          ) : (
            <div className='settings-section-subtitle'>
              Connect your social accounts for more in-depth analytics on your brand collaborations. For more information or help getting set up, see
              our general guide{' '}
              <a href='https://guide.shopmy.us/connecting-socials/3ViMaZXyQ3tB63YPsdRTPC' target='_blank' rel='noopener noreferrer'>
                here
              </a>{' '}
              or our Instagram troubleshooting guide{' '}
              <a
                href='https://guide.shopmy.us/connecting-socials/3ViMaZXyQ3tB63YPsdRTPC/common-instagram-connection-issues/44n76jmNPUf3o28mjKm5mE'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </div>
          )}

          <div className='connect-social-btns'>
            {accountConnections.map(connection => {
              const { type, Icon, account, connectAccount } = connection;
              const isConnecting = isAddingSocialAccountType === type;
              const isConnected = !!account;
              const disconnectAction = () => disconnectAccount(type);
              const connectBtnText = account?.instagramUsername || (isConnected ? 'Connected' : 'Connect');
              const connectBtnClass = isConnected ? `${type} disconnect` : type;

              return (
                <button
                  key={type}
                  onClick={isConnected ? disconnectAction : connectAccount}
                  className={`settings-button connect-social-btn ${connectBtnClass}`}
                >
                  <Icon className='social-icn' />
                  {isConnecting ? 'Connecting...' : connectBtnText}
                  {isConnected && <FontAwesomeIcon icon={faTimes} />}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

ConnectSocialsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired,
  disconnectSocialAccount: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  connectYoutube: PropTypes.func.isRequired,
  connectTiktok: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openConnectInstagramModal,
  disconnectSocialAccount,
  connectInstagram,
  connectYoutube,
  connectTiktok
})(ConnectSocialsPanel);
